import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, toast, ToastContainer } from 'mdbreact'
import { Zoom } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { Link } from 'gatsby'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name must be 2 characters at minimum')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email address format')
    .required('Email is required'),
  country: Yup.string()
    .min(2, 'Name must be 2 characters at minimum')
    .required('Country is required'),
  message: Yup.string()
    .min(5, 'Message must be 5 characters at minimum')
    .required('Message is required'),
})

const url =
  'https://script.google.com/macros/s/AKfycbwwNTzuLSqs65S09eZd1ddIyfn-y6vFTUHtXzC_oZ6ts1Yq-PDwAqOlTLBkTrJttsy_/exec'

const messageConfirm = () => {
  return toast.info(
    "Thank you for completing this form. We'll be in touch with your document shortly.",
    {
      closeButton: false,
      position: toast.POSITION.TOP_CENTER,
      transition: Zoom,
    }
  )
}

class UICContact extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      load: false,
      expired: 'false',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        load: true,
        pageUrl: location.href,
      })
    })
  }

  handleChange = (value) => {
    this.setState({ value })
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }

  render() {
    const { value, callback, load, expired } = this.state || {}

    return (
      <MDBContainer className="pl-0 pt-3">
        <MDBRow className="justify-content-center">
          <MDBCol md="8" className="md-0 mb-5">
            <Formik
              initialValues={{
                name: '',
                email: '',
                country: '',
                company: '',
                message: '',
                subscribe: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                  PageUrl: this.state.pageUrl,
                  Name: values.name,
                  Email: values.email,
                  Country: values.country,
                  Company: values.company,
                  Message: values.message,
                  Subscribe: values.subscribe,
                }
                try {
                  const response = axios({
                    method: 'get',
                    url: `${url}?timestamp=&url=${encodeURIComponent(this.state.pageUrl)}&name=${encodeURIComponent(values.name)}&email=${encodeURIComponent(values.email)}&company=${encodeURIComponent(values.company)}&comments=${encodeURIComponent(values.message)}&subscribe=${encodeURIComponent(values.subscribe)}`,})
                  this.setState({ values: '', expired: 'true' })
                  resetForm()
                  messageConfirm()
                } catch (e) {
                  console.log(`Axios request failed: ${e}`)
                }
                setSubmitting(false)
              }}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form
                  name="contact"
                  method="post"
                  action=""
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />

                  <section className="form-section rounded">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-700 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">
                            Request document
                          </h2>
                          <hr />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="6">
                        <div className="mb-4 grey-text font-w-700 text-medium">
                          <label htmlFor="name">
                            Name <span style={{ color: 'red' }}>*</span>
                          </label>
                          <Field
                            type="name"
                            name="name"
                            aria-label="name"
                            value={values.name}
                            placeholder="Your full name"
                            className={`form-control ${ touched.name && errors.name ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="name"
                            className="invalid-feedback"
                          />
                        </div>
                      </MDBCol>
                      <MDBCol md="6">
                        <div className="mb-4 grey-text font-w-700 text-medium">
                          <label htmlFor="email">
                            Email <span style={{ color: 'red' }}>*</span>
                          </label>
                          <Field
                            type="email"
                            name="email"
                            aria-label="email"
                            value={values.email}
                            placeholder="Your email address"
                            className={`form-control ${ touched.email && errors.email ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="email"
                            className="invalid-feedback"
                          />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="6">
                        <div className="mb-4 grey-text font-w-700 text-medium">
                          <label htmlFor="country">
                            Country <span style={{ color: 'red' }}>*</span>
                          </label>
                          <Field
                            type="text"
                            name="country"
                            aria-label="country"
                            value={values.country}
                            placeholder="Your country of residence"
                            className={`form-control ${ touched.country && errors.country ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="message"
                            className="invalid-feedback"
                          />
                        </div>
                      </MDBCol>
                      <MDBCol md="6">
                        <div className="mb-4 grey-text font-w-700 text-medium">
                          <label htmlFor="company">Company</label>
                          <Field
                            type="text"
                            name="company"
                            aria-label="company"
                            value={values.company}
                            placeholder="Your company"
                            className={`form-control ${ touched.company && errors.company ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="company"
                            className="invalid-feedback"
                          />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-700 text-medium">
                          <label htmlFor="message">
                            Message <span style={{ color: 'red' }}>*</span>
                          </label>
                          <Field
                            type="textarea"
                            component="textarea"
                            name="message"
                            aria-label="message"
                            value={values.message}
                            placeholder="Enter message"
                            className={`form-control ${ touched.message && errors.message ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="message"
                            className="invalid-feedback"
                          />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                          To receive occasional updates about products, services, news and events, please tick the box below. You can unsubscribe at any time.
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="form-group form-check mt-3 mb-4 grey-text font-w-400 text-small">
                          <Field
                            type="checkbox"
                            id="subscribe"
                            name="subscribe"
                            className={`form-check-input ${ touched.subscribe && errors.subscribe ? 'is-invalid' : '' }`}
                          />
                          <label htmlFor="subscribe">
                            Yes, I would like to receive communications from UNICOM Systems, Inc.
                          </label>
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-400 text-small">
                          For details on how your data is stored and used, please visit our <Link to="/privacy-statement/" className="effect"> Privacy Statement </Link> .
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12" className="mb-4">
                        <div>
                          <button
                            type="submit"
                            className="mt-4 btn btn-medium btn-mdb-color nav-link"
                            style={{ display: 'block' }}
                            disabled={isSubmitting ? true : false} > {isSubmitting ? 'Please wait...' : 'Submit'}
                          </button>
                          <ToastContainer
                            transition={Zoom}
                            hideProgressBar={true}
                            newestOnTop={true}
                            autoClose={5000}
                          />
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </section>
                </Form>
              )}
            </Formik>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default UICContact
