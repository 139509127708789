import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'

class cta extends React.Component {
  render() {
    return (
      <MDBRow>
        <MDBCol>
          <h3 className="font-alt font-w-700 text-white title-xs-medium title-large"> {this.props.title} </h3>
          <p className="mt-4 font-w-400 text-white text-sm-large text-large-cta"> {this.props.subtitle} </p>
        </MDBCol>
      </MDBRow>
    )
  }
}

export default cta
